import { unflatten } from 'flat';

type Color = {
  name: string;
  value: string;
  var: string;
  rootAlias: string;
};
type ColorMode = {
  mode: { name: string };
  color: Color[];
  number: Array<Record<string, string>>;
};
export type VariableSet = {
  css: KeyValueSet;
  scss: KeyValueSet;
  less: KeyValueSet;
  js: KeyValueSet;
  mode: string;
  usesAlias: boolean;
};
type KeyValueSet = {
  key: string;
  value: string;
  sanitizedValue?: string;
};
export type ColorFileData = {
  outputString: string;
  outputFileName: string;
  packageName?: string;
};

const SUPPORTED_COLOR_MODES = ['light', 'dark'];

export function createColorSet(fileData: string) {
  const colorsUnformated = JSON.parse(fileData);
  const colorsFormatedAndMappedToModes: VariableSet[] =
    colorsUnformated[0].values.reduce(
      (allComponentColors: VariableSet[], colorMode: ColorMode) => {
        const colorsFormatedForMode: VariableSet[] = colorMode.color.map(
          (color: Color) => {
            const colorNameInKebabCase = toKebabCase(color.name);
            const varNameInKebabCase = toKebabCase(color.var);
            const hexValue = color.value;
            const colorModeName = colorMode.mode.name.toLowerCase();
            const usesAlias = varNameInKebabCase !== '';

            return {
              css: {
                key: `--${colorNameInKebabCase}`,
                value: usesAlias ? `var(--${varNameInKebabCase})` : hexValue,
              },
              scss: {
                key: `$${colorNameInKebabCase}`,
                value: usesAlias ? `$${varNameInKebabCase}` : hexValue,
                sanitizedValue: usesAlias
                  ? `#{$${varNameInKebabCase}}`
                  : hexValue,
              },
              less: {
                key: `@${colorNameInKebabCase}`,
                value: usesAlias ? `@${varNameInKebabCase}` : hexValue,
              },
              js: {
                key: `${
                  SUPPORTED_COLOR_MODES.includes(colorModeName)
                    ? colorModeName + '.'
                    : ''
                }${toFlattenedJSObjectKey(color.name)}`,
                value: hexValue,
              },
              mode: colorModeName,
              usesAlias: usesAlias,
            };
          },
        );

        return [...allComponentColors, ...colorsFormatedForMode];
      },
      [],
    );
  return colorsFormatedAndMappedToModes;
}

const WARNING_TEXT = `/* DO NOT CHANGE!*/
/* This file is automatically generated from @entur/tokens! Changes will be overwritten. */`;

function createColorsOutputString({
  colorSet,
  keyType,
  valueType,
  withColorMode,
  importFileNames,
}: {
  colorSet: VariableSet[];
  keyType: 'css' | 'scss' | 'less';
  valueType: 'css' | 'scss' | 'less';
  withColorMode?: boolean;
  importFileNames?: string[];
}) {
  const needsRoot = keyType === 'css';
  const needsImport = colorSet.some(color => color.usesAlias);

  if (withColorMode) {
    if (needsImport && importFileNames) {
      const importString = createImportStatementString({
        fileNames: importFileNames,
        extension: valueType,
        includeAs: needsImport && valueType === 'scss',
      });
      const colorVariablesString = stringWithColorMode(colorSet);
      return `${importString}
      ${colorVariablesString}
      `;
    }
    return stringWithColorMode(colorSet);
  } else {
    if (needsImport && importFileNames) {
      const importString = createImportStatementString({
        fileNames: importFileNames,
        extension: valueType,
        includeAs: needsImport && valueType === 'scss',
      });
      const colorVariablesString = stringWithoutColorMode(colorSet, needsRoot);
      return `${importString}
      ${colorVariablesString}
      `;
    }
    return stringWithoutColorMode(colorSet, needsRoot);
  }

  function stringWithColorMode(colorSet: VariableSet[]) {
    const outputString = `
${WARNING_TEXT}
[data-color-mode='light'],
:root {
  ${colorSet
    .filter(color => color?.mode === 'light')
    .map(color => {
      const colorKey = color[keyType].key;
      const colorValue =
        keyType === 'css' && valueType === 'scss'
          ? color[valueType].sanitizedValue
          : color[valueType].value;
      return `${colorKey}: ${colorValue};`;
    })
    .join('\n  ')}
}

[data-color-mode='dark'] {
  ${colorSet
    .filter(color => color?.mode === 'dark')
    .map(color => {
      const colorKey = color[keyType].key;
      const colorValue =
        keyType === 'css' && valueType === 'scss'
          ? color[valueType].sanitizedValue
          : color[valueType].value;
      return `${colorKey}: ${colorValue};`;
    })
    .join('\n  ')}
}
`;

    return outputString;
  }
  function stringWithoutColorMode(colorSet: VariableSet[], needsRoot: boolean) {
    const outputString = `
${WARNING_TEXT}
${needsRoot ? ':root {' : ''}
${needsRoot ? '\t' : ''}${colorSet
      .map(color => {
        const colorKey = color[keyType].key;
        const colorValue =
          keyType === 'css' && valueType === 'scss'
            ? color[valueType].sanitizedValue
            : color[valueType].value;
        return `${colorKey}: ${colorValue};`;
      })
      .join(`\n${needsRoot ? '\t' : ''}`)}
${needsRoot ? '}' : ''}
  `;
    return outputString;
  }
  function createImportStatementString({
    fileNames,
    extension,
    includeAs,
  }: {
    fileNames: string[];
    extension: 'css' | 'scss' | 'less';
    includeAs?: boolean;
  }) {
    const IMPORT_SYNTAX = { css: '@import', scss: '@use', less: '@import' };
    const asStatement = includeAs ? ' as *' : '';
    const importStatements = fileNames.map(fileName => {
      const importPath = `'@entur/tokens/dist/${fileName}.${extension}'`;
      return `${extension === 'scss' ? '@forward ' + importPath + ';\n' : ''}${
        IMPORT_SYNTAX[extension]
      } ${importPath}${asStatement};`;
    });

    return importStatements.join('\n');
  }
}

export function createColorsFileData({
  colorSet,
  keyType,
  valueType,
  name,
  outputToPackages,
  withColorMode = false,
  importFileNames,
}: {
  colorSet: VariableSet[];
  keyType: 'css' | 'scss' | 'less';
  valueType: 'css' | 'scss' | 'less';
  name: string;
  outputToPackages?: string[];
  withColorMode?: boolean;
  importFileNames?: string[];
}) {
  let outputString = '';

  if (outputToPackages) {
    let outputData: ColorFileData[] = [];
    outputToPackages.forEach(packageName => {
      outputString = '';
      const variablesForCurrentPackage = colorSet.filter(variable =>
        variable.scss.key.includes(`-${packageName}-`),
      );

      if (variablesForCurrentPackage.length === 0) {
        return;
      }

      if (packageName === 'travel') {
        outputString += createColorsOutputString({
          colorSet: variablesForCurrentPackage,
          keyType,
          valueType,
          withColorMode: true,
          importFileNames: ['semantic', 'transport'],
        });
      } else {
        outputString += createColorsOutputString({
          colorSet: variablesForCurrentPackage,
          keyType,
          valueType,
          withColorMode: true,
          importFileNames: ['semantic'],
        });
      }

      outputData.push({
        outputString,
        outputFileName: `${name}.scss`,
        packageName,
      });
    });
    return outputData;
  }

  switch (name) {
    case 'base':
      outputString += createColorsOutputString({
        colorSet,
        keyType,
        valueType,
        withColorMode: true,
        importFileNames: ['semantic', 'transport'],
      });
      break;
    case 'semantic':
      outputString += createColorsOutputString({
        colorSet,
        keyType,
        valueType,
        withColorMode: false,
        importFileNames: ['primitive'],
      });
      break;
    case 'data':
      outputString += createColorsOutputString({
        colorSet,
        keyType,
        valueType,
        withColorMode: true,
      });
      break;
    default:
      outputString += createColorsOutputString({
        colorSet,
        keyType,
        valueType,
        withColorMode,
        importFileNames,
      });
  }

  return [
    { outputString, outputFileName: `${name}.${valueType}` },
  ] as ColorFileData[];
}

export function createJSColorFileData({
  variables,
  name,
}: {
  variables: VariableSet[];
  name: string;
}) {
  const jsVariables = variables.map(color => ({
    [color?.js.key]: color?.js.value,
  }));
  const jsVariablesObject = Object.assign({}, ...jsVariables);
  const unflattenedJSVariablesObject = unflatten(jsVariablesObject);

  const outputString = `${WARNING_TEXT}
 export const ${name} = ${JSON.stringify(unflattenedJSVariablesObject)}
 `;

  return { outputString: outputString, outputFileName: `${name}.ts` };
}

function toKebabCase(name: string) {
  return name.replace(/\//g, '-').replace(/\s/g, '').toLowerCase();
}

function toFlattenedJSObjectKey(name: string) {
  const nameWithoutSpacesAndDashes = name
    .toLowerCase()
    .replace(/\s(\w)/g, (_, letter) => letter.toUpperCase())
    .replace(/\-(\w)/g, (_, letter) => letter.toUpperCase());
  const nameArray = nameWithoutSpacesAndDashes.split('/');

  const nameArrayWithStringNumbers = nameArray.map(namePart => {
    if (!isNaN(Number(namePart.charAt(0)))) {
      return `_${namePart}`;
    }
    return namePart;
  });
  return nameArrayWithStringNumbers.join('.');
}
